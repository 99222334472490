import AsyncStorage from "@react-native-async-storage/async-storage";
import { useNavigation } from "@react-navigation/native";
import React from "react";
import {
    View,
    Text,
    Image,
    ImageBackground,
    TouchableOpacity,
} from "react-native";
import { MonoText } from "../../components/StyledText";
import myIndice from "../../constants";
import Aula from "../../interfaces/Aulas";
export default function Home() {
    const navigation = useNavigation();

    return (
        <View
            style={{
                flex: 1,
                backgroundColor: "#fff",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <ImageBackground
                source={require("../../../assets/images/background.png")}
                resizeMode="stretch"
                style={{
                    width: "100%",
                    height: "100%",
                    // padding: 32,
                    flex: 1,
                    alignItems: "center",
                }}
            >
                <ImageBackground
                    resizeMode="contain"
                    source={require("../../../assets/images/nova-capa.png")}
                    style={{
                        height: "100%",
                        width: "100%",
                        margin: 16,
                        flex: 1,
                        alignItems: "flex-end",
                        justifyContent: "flex-start",
                    }}
                >
                    <TouchableOpacity
                        style={{
                            alignSelf: "flex-end",
                            width: "100%",
                            maxWidth: 100,
                            height: "100%",
                            maxHeight: 100,
                            zIndex: 1,
                        }}
                        onPress={async () => {
                            navigation.navigate("Login");
                            await AsyncStorage.setItem("dataUserPassword", "");
                        }}
                    >
                        <Image
                            style={{
                                width: "100%",
                                height: "100%",
                            }}
                            resizeMode={"contain"}
                            source={require("../../../assets/images/close.png")}
                        />
                    </TouchableOpacity>
                </ImageBackground>
                <View
                    style={{
                        // flex: 1,
                        height: "100%",
                        width: "100%",
                        maxHeight: 100,
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "row",
                        flexWrap: "wrap",
                    }}
                >
                    <TouchableOpacity
                        style={{
                            width: "100%",
                            height: "100%",
                            maxWidth: 240,
                            // marginTop: 32,
                        }}
                        onPress={() => navigation.navigate("Summary")}
                    >
                        <Image
                            style={{
                                width: "100%",
                                height: "100%",
                                padding: 8,
                            }}
                            resizeMode={"contain"}
                            source={require("../../../assets/images/indice-button.png")}
                        />
                    </TouchableOpacity>
                    <TouchableOpacity
                        style={{
                            width: "100%",
                            height: "100%",
                            maxWidth: 240,
                            // marginTop: 32,
                        }}
                        onPress={() =>
                            navigation.navigate("startHomePage", {
                                activeAula: 0,
                            })
                        }
                    >
                        <Image
                            style={{
                                width: "100%",
                                height: "100%",
                                padding: 8,
                            }}
                            resizeMode={"contain"}
                            source={require("../../../assets/images/comecar-button.png")}
                        />
                    </TouchableOpacity>
                    <TouchableOpacity
                        style={{
                            width: "100%",
                            height: "100%",
                            maxWidth: 240,
                            // marginTop: 32,
                        }}
                        onPress={() => navigation.navigate("Extras")}
                    >
                        <Image
                            style={{
                                width: "100%",
                                height: "100%",
                                padding: 8,
                            }}
                            resizeMode={"contain"}
                            source={require("../../../assets/images/extra-button.png")}
                        />
                    </TouchableOpacity>
                </View>
            </ImageBackground>
        </View>
    );
}
