/**
 * Learn more about deep linking with React Navigation
 * https://reactnavigation.org/docs/deep-linking
 * https://reactnavigation.org/docs/configuring-links
 */

import { LinkingOptions } from "@react-navigation/native";
import * as Linking from "expo-linking";

import { RootStackParamList } from "../../types";
import playAudio from "../screens/Home/Start/Audio";
import playVideo from "../screens/Home/Start/Video";

const linking: LinkingOptions<RootStackParamList> = {
    prefixes: [Linking.makeUrl("/")],
    config: {
        screens: {
            Login: "Login",
            Home: "Home",
            startHomePage: "Aula",
            playAudio: "playAudio",
            playVideo: "playVideo",
            NotFound: "*",
            Extras: "Extras",
            Summary: "Summary",
        },
    },
};

export default linking;
