import React, { createContext, useState } from "react";

const AuthContext = createContext({});

export const AuthContextProvider: React.FC = ({ children }) => {
    const [authInfo, setAuthInfo] = useState<string | null>(null);

    return (
        <AuthContext.Provider value={[authInfo, setAuthInfo]}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthContext;
