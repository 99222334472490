import { StyleSheet } from "react-native";

interface props {
    range: number;
}
export function MySlider({ ...props }) {
    return <input type={"range"} min={50} max={150} value={props.range} />;
}

const styles = StyleSheet.create({
    container: {},
});
