import {
    Button,
    Dimensions,
    Image,
    ImageBackground,
    ScrollView,
    Text,
    TouchableOpacity,
    View,
} from "react-native";
import { useNavigation } from "@react-navigation/native";
import Slider from "@react-native-community/slider";
import { useContext, useEffect, useRef, useState } from "react";
import Aula from "../../../interfaces/Aulas";
import myIndice from "../../../constants";
import MusicContext from "../../../contexts/aulaContext";
interface Params {
    activeAula: number;
}
export default function startHomePage({ route }: any) {
    const { activeAula }: Params = route.params;
    const navigation = useNavigation();
    const [cap, setCap] = useState<any>();
    const [showComponent, setShowComponent] = useState(0);
    const [aula, setAula] = useState<number>(0);
    const [activeChannel] = useContext(MusicContext);

    useEffect(() => {}, [showComponent]);
    const getNextVideo = (props: number) => {
        return activeChannel[activeAula].id + props;
    };

    const GotoPlay = () => {
        if (activeChannel[activeAula].audio != "") {
            navigation.navigate("playAudio", { activeAudio: activeAula });
        } else {
            navigation.navigate("playVideo", { activeVideo: activeAula });
        }
    };
    return (
        <View
            style={{
                flex: 1,

                backgroundColor: "#fff",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <ImageBackground
                source={require("../../../../assets/images/background.png")}
                resizeMode="stretch"
                style={{ width: "100%", height: "100%", padding: 16, flex: 1 }}
            >
                <View
                    style={{
                        width: "100%",
                        height: "100%",
                        alignItems: "center",
                        justifyContent: "center",
                        zIndex: 1,
                    }}
                >
                    {
                        <View
                            style={{
                                width: "100%",
                                height: "100%",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            {activeAula > myIndice[0].id && (
                                <TouchableOpacity
                                    style={{
                                        alignSelf: "flex-start",
                                        width: "100%",
                                        maxWidth: 100,
                                        height: "100%",
                                        maxHeight: 100,
                                        position: "absolute",
                                        zIndex: 2,
                                    }}
                                    onPress={() => {
                                        navigation.navigate("startHomePage", {
                                            activeAula: getNextVideo(-1),
                                        });
                                    }}
                                >
                                    <Image
                                        style={{
                                            width: "100%",
                                            height: "100%",
                                        }}
                                        resizeMode={"contain"}
                                        source={require("../../../../assets/images/back-arrow.png")}
                                    />
                                </TouchableOpacity>
                            )}

                            <View
                                style={{
                                    alignSelf: "flex-start",
                                    alignItems: "flex-start",
                                    justifyContent: "flex-start",
                                    width: "100%",
                                    maxWidth: 100,
                                    height: "100%",
                                    position: "absolute",
                                    zIndex: 1,
                                }}
                            >
                                <TouchableOpacity
                                    style={{
                                        alignSelf: "flex-start",
                                        width: "100%",
                                        maxWidth: 100,
                                        height: "100%",
                                        maxHeight: 100,
                                    }}
                                    onPress={() => {
                                        navigation.navigate("Home");
                                    }}
                                >
                                    <Image
                                        style={{
                                            width: "100%",
                                            height: "100%",
                                        }}
                                        resizeMode={"contain"}
                                        source={require("../../../../assets/images/menu.png")}
                                    />
                                </TouchableOpacity>
                            </View>
                            <ImageBackground
                                resizeMode="contain"
                                source={require(`../../../../${activeChannel[activeAula].image[0]}`)}
                                style={{
                                    height: "100%",
                                    width: "100%",
                                    minHeight: 400,
                                    margin: 16,
                                    flex: 1,
                                    alignItems: "center",
                                    justifyContent: "flex-end",
                                }}
                            >
                                <TouchableOpacity
                                    style={{
                                        padding: 0,
                                        top: 0,
                                        margin: 0,
                                        width: "70%",
                                        height: "70%",
                                    }}
                                    onPress={GotoPlay}
                                />

                                <TouchableOpacity onPress={GotoPlay}>
                                    <Image
                                        resizeMode="cover"
                                        style={{
                                            width: 100,
                                            height: 100,
                                            padding: 16,
                                        }}
                                        source={require("../../../../assets/images/play-audio.png")}
                                    />
                                </TouchableOpacity>
                            </ImageBackground>
                            {/*  <View
                                style={{
                                    width: "100%",
                                    height: "100%",
                                    marginTop: 32,
                                    maxHeight: 110,
                                    alignItems: "center",
                                    flexDirection: "column",
                                    justifyContent: "flex-end",
                                }}
                            >
                               <Image
                                    resizeMode="contain"
                                    source={require("../../../../assets/images/historia-0.png")}
                                    style={{
                                        width: "100%",
                                        height: "100%",
                                    }}
                                /> */}
                            {/* <ImageBackground
                                    resizeMode="contain"
                                    source={require("../../../../assets/images/route-treasure.png")}
                                    style={{
                                        height: "100%",
                                        width: "100%",
                                        maxWidth: "720px",
                                        // margin: 8,
                                        maxHeight: 50,
                                        // zIndex: 1,
                                    }}
                                >
                                    <Slider
                                        style={{
                                            height: "100%",
                                            width: "100%",
                                        }}
                                        step={0.125}
                                        minimumValue={0}
                                        maximumValue={1}
                                        thumbTintColor={"#964b00"}
                                        thumbImage={require("../../../../assets/images/icon-andamento.png")}
                                        minimumTrackTintColor="none"
                                        maximumTrackTintColor="none"
                                    />
                                </ImageBackground> 
                                    </View>*/}
                            {activeAula !=
                                activeChannel[activeChannel.length - 1].id && (
                                <TouchableOpacity
                                    style={{
                                        alignSelf: "flex-end",
                                        width: "100%",
                                        maxWidth: 100,
                                        height: "100%",
                                        maxHeight: 100,
                                        position: "absolute",
                                        zIndex: 1,
                                    }}
                                    onPress={() => {
                                        navigation.navigate("startHomePage", {
                                            activeAula: getNextVideo(1),
                                        });
                                    }}
                                >
                                    <Image
                                        style={{
                                            width: "100%",
                                            height: "100%",
                                        }}
                                        resizeMode={"contain"}
                                        source={require("../../../../assets/images/righ-arrow.png")}
                                    />
                                </TouchableOpacity>
                            )}
                        </View>
                    }
                </View>
            </ImageBackground>
        </View>
    );
}
