import { Video, Audio } from "expo-av";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
    FlatList,
    View,
    StyleSheet,
    Button,
    ImageBackground,
    Dimensions,
    TouchableOpacity,
    Image,
} from "react-native";
import { useNavigation } from "@react-navigation/native";
import MusicContext from "../../../../contexts/aulaContext";
interface Params {
    activeVideo: any;
}
export default function playVideo({ route }: any) {
    const { activeVideo }: Params = route.params;

    const video = useRef(null);
    const [wid, setWid] = useState<number>();
    const windowWidth = Dimensions.get("window").width;
    const navigation = useNavigation();
    const [activeChannel] = useContext(MusicContext);
    useEffect(() => {
        setWid(windowWidth);
        // alert(JSON.stringify(activeChannel));
    }, [wid, video]);

    return (
        <View
            style={{
                flex: 1,
                alignContent: "center",
                backgroundColor: "#ffffff",
                justifyContent: "center",
            }}
        >
            <ImageBackground
                source={require("../../../../../assets/images/background.png")}
                resizeMode="stretch"
                style={{ width: "100%", height: "100%", padding: 16 }}
            >
                <TouchableOpacity
                    style={{
                        alignSelf: "flex-start",
                        width: "100%",
                        maxWidth: 100,
                        height: "100%",
                        paddingTop: 32,
                        maxHeight: 100,
                        position: "absolute",
                        zIndex: 1,
                    }}
                    onPress={() => {
                        navigation.navigate("Home");
                    }}
                >
                    <Image
                        style={{
                            width: "100%",
                            height: "100%",
                        }}
                        resizeMode={"contain"}
                        source={require("../../../../../assets/images/menu.png")}
                    />
                </TouchableOpacity>
                <TouchableOpacity
                    style={{
                        alignSelf: "flex-end",
                        width: "100%",
                        maxWidth: 100,
                        height: "100%",
                        maxHeight: 100,
                        zIndex: 1,
                    }}
                    onPress={() => {
                        navigation.navigate("startHomePage", {
                            activeAula: activeVideo,
                        });
                    }}
                >
                    <Image
                        style={{
                            width: "100%",
                            height: "100%",
                        }}
                        resizeMode={"contain"}
                        source={require("../../../../../assets/images/close.png")}
                    />
                </TouchableOpacity>
                <View
                    style={{
                        minWidth: 320,
                        height: "80%",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    {/* <FlatList
                        data={startVideos}
                        keyExtractor={(item: any) => item.id}
                        renderItem={({ item }) => (
                            <View
                                style={{
                                    width: "100%",
                                    minWidth: 320,
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            > */}
                    {wid! < 400 && (
                        <Video
                            ref={video}
                            style={{
                                borderRadius: 16,
                                alignSelf: "center",
                                width: "100%",
                                maxWidth: 300,
                            }}
                            source={require(`../../../../../${activeChannel[activeVideo].video}`)}
                            // source={require(`../../../../../${activeAudio.partitura}`)}

                            isLooping
                            useNativeControls
                            shouldPlay={true}
                            resizeMode={Video.RESIZE_MODE_COVER}
                        />
                    )}
                    {wid! > 400 && (
                        <Video
                            ref={video}
                            style={{
                                borderRadius: 16,
                                alignSelf: "center",
                                height: "100%",
                                width: "100%",
                            }}
                            source={require(`../../../../../${activeChannel[activeVideo].video}`)}
                            isLooping
                            useNativeControls
                            shouldPlay={false}
                            resizeMode={Video.RESIZE_MODE_COVER}
                        />
                    )}
                    {/* </View>
                        )}
                    /> */}
                </View>
            </ImageBackground>
        </View>
    );
}
const styles = StyleSheet.create({
    video: {
        alignSelf: "center",
        width: 320,
        height: 200,
    },
});
