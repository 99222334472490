import React, { useState } from "react";
import { Text } from "react-native";
import { Snackbar } from "react-native-paper";

interface CustomSnackbarProps {
    text: string;
    duration: number;
    error?: boolean;
    onPress?: Function;
}

const CustomSnackbar: React.FC<CustomSnackbarProps> = ({
    text,
    duration,
    error = false,
    onPress,
}) => {
    const [visible, setIsVisible] = useState(true);

    return (
        <Snackbar
            visible={visible}
            duration={duration}
            onDismiss={() => {
                setIsVisible(false);
                if (onPress) {
                    onPress();
                }
            }}
            action={{
                label: "Fechar",
                color: "#fff",
            }}
            style={{
                backgroundColor: error ? "#FC6E47" : "#1FAD66",
            }}
        >
            <Text style={{ fontSize: 16, color: "white" }}>{text}</Text>
        </Snackbar>
    );
};

export default CustomSnackbar;
