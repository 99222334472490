import React, { createContext, useContext, useState } from "react";
import myIndice from "../../constants";

const MusicContext = createContext([myIndice]);

export const MusicContextProvider: React.FC = ({ children }) => {
    const [activeChannel, setActiveChannel] = useState(myIndice);

    return (
        <MusicContext.Provider value={[activeChannel]}>
            {children}
        </MusicContext.Provider>
    );
};

export default MusicContext;
