import { useNavigation } from "@react-navigation/native";
import React, { useContext, useEffect, useState } from "react";
import {
    View,
    Text,
    TextInput,
    Image,
    Button,
    StyleSheet,
    TouchableOpacity,
    ScrollView,
    Dimensions,
    ImageBackground,
} from "react-native";
import Ionicons from "@expo/vector-icons/Ionicons";
import { Checkbox } from "react-native-paper";
import CustomSnackbar from "../../CustomSnackbar";
import { useMediaQuery } from "react-responsive";
import "@expo/match-media";
import AsyncStorage from "@react-native-async-storage/async-storage";
import AuthContext from "../../contexts/authContext";

export default function Login() {
    // const [login, onChangeLogin] = useState("berg@masterclass.com.br");
    const [login, onChangeLogin] = useState("masterclass");
    const [password, onChangePassword] = useState("");
    // const [password, onChangePassword] = useState("masterCLASS123");

    const navigation = useNavigation();
    const [checked, setChecked] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);

    const isTabletOrMobileDevice = useMediaQuery({
        maxDeviceWidth: 1224,
        query: "(max-device-width: 1224px)",
    });
    const handleLogin = async () => {
        if (login === "masterclass" && password === "masterCLASS123") {
            await AsyncStorage.setItem("dataUserPassword", password);
            navigation.navigate("Home");
        } else {
            setError(true);
        }
    };
    const getAsync = async () => {
        const pass = await AsyncStorage.getItem("dataUserPassword");
        if (pass === "masterCLASS123") {
            navigation.navigate("Home");
        }
    };

    useEffect(() => {
        getAsync();
    }, []);
    // if (isTabletOrMobileDevice) {
    return (
        // <View
        //     style={{
        //         flex: 1,
        //         alignItems: "center",
        //         justifyContent: "center",
        //         backgroundColor: "#57C2F0",
        //     }}
        // >
        <ImageBackground
            // source={require("../../../assets/images/nadando.png")}
            source={require("../../../assets/images/background.png")}
            resizeMode="stretch"
            style={{
                width: "100%",
                height: "100%",
                flex: 1,
                alignItems: "center",
            }}
        >
            <View
                style={{
                    flex: 1,
                    width: "100%",
                    height: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "row",
                }}
            >
                <Image
                    style={{
                        width: "50%",
                        height: "70%",
                        marginLeft: "10%",
                        resizeMode: "center",
                    }}
                    source={require("../../../assets/images/logo.png")}
                />

                <View
                    style={{
                        flex: 1,
                        justifyContent: "center",
                        height: "100%",
                        width: "50%",
                        alignItems: "center",
                        flexDirection: "column",
                        backgroundColor: "#57C2F0",
                    }}
                >
                    <View
                        style={{
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "center",
                            padding: 4,
                        }}
                    >
                        <Ionicons name="person" size={24} color="white" />
                        <Text style={{ padding: 8, color: "white" }}>
                            Usuário
                        </Text>
                        <TextInput
                            style={{
                                padding: 8,
                                borderStyle: "solid",
                                borderWidth: 1,
                                borderRadius: 4,
                                backgroundColor: "#ffffff",
                                borderColor: "#ffffff",
                            }}
                            onContentSizeChange={() => {
                                100;
                            }}
                            value={login}
                            onChangeText={onChangeLogin}
                            placeholder="berg@masterclass.com.br"
                        />
                    </View>
                    <View
                        style={{
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "center",
                            padding: 4,
                        }}
                    >
                        <Ionicons name="lock-closed" size={24} color="white" />
                        <Text style={{ padding: 8, color: "white" }}>
                            Senha
                        </Text>
                        <TextInput
                            style={{
                                padding: 8,
                                borderWidth: 1,
                                borderColor: "#ffffff",
                                backgroundColor: "#ffffff",
                                borderRadius: 4,
                            }}
                            value={password}
                            onChangeText={onChangePassword}
                            secureTextEntry={true}
                        />
                    </View>
                    <View
                        style={{
                            flexDirection: "row",
                            alignItems: "center",
                        }}
                    >
                        <Checkbox
                            status={checked ? "checked" : "unchecked"}
                            color={"white"}
                            onPress={() => setChecked((value) => !value)}
                        />
                        <Text style={{ marginLeft: 8, color: "#ffffff" }}>
                            Lembrar-me
                        </Text>
                    </View>

                    <TouchableOpacity
                        style={{
                            backgroundColor: "yellow",
                            padding: 8,
                            borderRadius: 4,
                            width: 100,
                            alignItems: "center",
                        }}
                        onPress={() => handleLogin()}
                    >
                        <Text>Entrar</Text>
                    </TouchableOpacity>
                    {error && (
                        <CustomSnackbar
                            error
                            text="Um erro ocorreu. Verifique suas informações."
                            duration={6000}
                            onPress={() => setError(false)}
                        />
                    )}
                </View>
            </View>
        </ImageBackground>
        // </View>
    );
    // }
    // return <Text>👋 Hello Desktop People</Text>;
}
