import {
    View,
    Button,
    Image,
    Text,
    ImageBackground,
    TouchableOpacity,
    FlatList,
    StyleSheet,
} from "react-native";
import { StatusBar } from "expo-status-bar";

import React, { useEffect, useRef, useState } from "react";
import Slider from "@react-native-community/slider";
import { useNavigation } from "@react-navigation/native";
import { MonoText } from "../../components/StyledText";
import myIndice from "../../constants";
import Aula from "../../interfaces/Aulas";

export default function Summary() {
    const navigation = useNavigation();
    const [firstColumn, setFirstColumn] = useState<Aula[]>([]);
    const [secondColumn, setSecondColumn] = useState<Aula[]>([]);
    const [thirdColumn, setThirdColumn] = useState<Aula[]>([]);
    const flat1 = (
        <FlatList
            data={firstColumn}
            keyExtractor={(item: any) => item.id}
            renderItem={({ item }) => (
                <View
                    style={{
                        width: "100%",
                        height: "100%",
                        padding: 4,
                        marginLeft: "10%",
                        minWidth: 320,
                        alignItems: "flex-start",
                        justifyContent: "center",
                        flex: 1,
                    }}
                >
                    <TouchableOpacity
                        style={{
                            backgroundColor: "#b3e6ff",
                            padding: 4,
                            borderRadius: 4,
                            height: "100%",
                            flexDirection: "row",
                            width: "100%",
                        }}
                        accessible
                        onPress={() =>
                            navigation.navigate("startHomePage", {
                                activeAula: item.id,
                            })
                        }
                    >
                        <MonoText style={{ color: "#002266" }}>
                            {item.id + 1}- {item.title}
                        </MonoText>
                    </TouchableOpacity>
                </View>
            )}
        />
    );

    const flat2 = (
        <FlatList
            data={secondColumn}
            keyExtractor={(item: any) => item.id}
            renderItem={({ item }) => (
                <View
                    style={{
                        width: "100%",
                        height: "100%",
                        padding: 4,
                        marginLeft: "10%",
                        minWidth: 320,
                        alignItems: "flex-start",
                        justifyContent: "center",
                        flex: 1,
                    }}
                >
                    <TouchableOpacity
                        style={{
                            backgroundColor: "#b3e6ff",
                            padding: 4,
                            borderRadius: 4,
                            height: "100%",
                            flexDirection: "row",
                            width: "100%",
                        }}
                        accessible
                        onPress={() =>
                            navigation.navigate("startHomePage", {
                                activeAula: item.id,
                            })
                        }
                    >
                        <MonoText style={{ color: "#002266" }}>
                            {item.id + 1}- {item.title}
                        </MonoText>
                    </TouchableOpacity>
                </View>
            )}
        />
    );
    const flat3 = (
        <FlatList
            data={thirdColumn}
            keyExtractor={(item: any) => item.id}
            renderItem={({ item }) => (
                <View
                    style={{
                        width: "100%",
                        height: "100%",
                        padding: 4,
                        marginLeft: "10%",
                        minWidth: 320,
                        alignItems: "flex-start",
                        justifyContent: "center",
                        flex: 1,
                    }}
                >
                    <TouchableOpacity
                        style={{
                            backgroundColor: "#b3e6ff",
                            padding: 4,
                            borderRadius: 4,
                            height: "100%",
                            flexDirection: "row",
                            width: "100%",
                        }}
                        accessible
                        onPress={() =>
                            navigation.navigate("startHomePage", {
                                activeAula: item.id,
                            })
                        }
                    >
                        <MonoText style={{ color: "#002266" }}>
                            {item.id + 1}- {item.title}
                        </MonoText>
                    </TouchableOpacity>
                </View>
            )}
        />
    );

    const columnsList = [
        {
            id: 0,
            comp: flat1,
        },
        { id: 1, comp: flat2 },
        { id: 2, comp: flat3 },
    ];
    function returnFirstColumn() {
        let column1: any[] = [];
        for (let i = 0; i <= 17; i++) {
            column1.push(myIndice[i]);
        }

        setFirstColumn(column1);
    }
    function returnSecondColumn() {
        let column2: any[] = [];

        for (let i = 18; i <= 35; i++) {
            column2.push(myIndice[i]);
        }
        setSecondColumn(column2);
    }
    function returnThirdColumn() {
        let column3: any[] = [];

        for (let i = 36; i < myIndice.length; i++) {
            column3.push(myIndice[i]);
        }
        setThirdColumn(column3);
    }

    useEffect(() => {
        returnFirstColumn();

        returnSecondColumn();
        returnThirdColumn();
    }, []);
    return (
        <View
            style={{
                flex: 1,
                backgroundColor: "#fff",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <ImageBackground
                source={require("../../../assets/images/background.png")}
                resizeMode="stretch"
                style={{
                    width: "100%",
                    height: "100%",
                    padding: 8,
                    overflow: "hidden",
                }}
            >
                <TouchableOpacity
                    style={{
                        alignSelf: "flex-start",
                        width: "100%",
                        maxWidth: 100,
                        height: "100%",
                        paddingTop: 32,
                        maxHeight: 100,
                        position: "absolute",
                        zIndex: 1,
                    }}
                    onPress={() => {
                        navigation.navigate("Home");
                    }}
                >
                    <Image
                        style={{
                            width: "100%",
                            height: "100%",
                        }}
                        resizeMode={"contain"}
                        source={require("../../../assets/images/back-arrow.png")}
                    />
                </TouchableOpacity>
                <MonoText style={{ fontSize: 48, alignSelf: "center" }}>
                    Em busca do Tesouro
                </MonoText>
                <FlatList
                    style={{ padding: 0 }}
                    data={columnsList}
                    keyExtractor={(item: any) => item.id}
                    renderItem={({ item }) => (
                        <View
                            style={{
                                width: "100%",
                                height: "100%",
                                padding: 8,
                                marginLeft: "5%",
                                minWidth: 320,
                                alignItems: "flex-start",
                                justifyContent: "center",
                                flex: 1,
                                flexDirection: "column",
                            }}
                        >
                            {item.comp}
                        </View>
                    )}
                    numColumns={3}
                />
            </ImageBackground>
        </View>
    );
}
