const myIndice = [
    {
        id: 0,
        title: "Instrodução",
        image: ["assets/images/introducao.png"],
        audio: "",
        partitura: "",
        video: "assets/videos/aventura.mp4",
    },
    {
        id: 1,
        title: "O Farol",
        image: ["assets/images/farol.png"],
        audio: "audio/farol.mp3",
        partitura: ["assets/images/partitura-farol.png"],
        video: "assets/videos/farol.MP4",
    },
    {
        id: 2,
        title: "Três Gaivotas",
        image: ["assets/images/gaivotas-capa.png"],
        audio: "audio/gaivotas.mp3",
        partitura: ["assets/images/gaivotas-partitura.png"],

        video: "assets/videos/farol copy 2.MP4",
    },
    {
        id: 3,
        title: "Jatiúca",
        image: ["assets/images/jatiuca-capa.png"],
        audio: "audio/jatiuca.mp3",
        partitura: ["assets/images/jatiuca-partitura.png"],

        video: "assets/videos/farol copy 3.MP4",
    },
    {
        id: 4,
        title: "Jaraguá",
        image: ["assets/images/jaragua-capa.png"],
        audio: "audio/jaragua.mp3",
        partitura: ["assets/images/jaragua-partitura.png"],

        video: "assets/videos/farol copy 4.MP4",
    },
    {
        id: 5,
        title: "Pipa",
        image: ["assets/images/pipa-capa.png"],
        video: "assets/videos/farol copy 5.MP4",

        partitura: ["assets/images/pipa-partitura.png"],

        audio: ["audio/pipa.mp3"],
    },
    {
        id: 6,
        title: "Três Peixinhos",
        image: ["assets/images/peixe-capa.png"],
        video: "assets/videos/farol copy 6.MP4",
        partitura: ["assets/images/peixe-partitura.png"],

        audio: ["audio/peixe.mp3"],
    },
    {
        id: 7,
        title: "O Coqueiro",
        image: ["assets/images/coqueiro-capa.png"],
        audio: "audio/coqueiro.mp3",
        partitura: ["assets/images/coqueiro-partitura.png"],

        video: "assets/videos/farol copy 7.MP4",
    },
    {
        id: 8,
        title: "Linda Maceió",
        image: ["assets/images/linda-capa.png"],
        audio: "audio/linda.mp3",
        partitura: ["assets/images/linda-partitura.png"],

        video: "assets/videos/farol copy 8.MP4",
    },
    {
        id: 9,
        title: "Mutum",
        image: ["assets/images/mutum-capa.png"],
        audio: "audio/mutum.mp3",
        partitura: "assets/images/mutum-partitura.png",

        video: "assets/videos/farol copy 9.MP4",
    },
    {
        id: 10,
        title: "Miniaturas de Arlindo",
        image: ["assets/images/mini-capa.png"],
        audio: "audio/mini.mp3",
        partitura: ["assets/images/mini-partitura.png"],

        video: "assets/videos/farol copy 10.MP4",
    },
    {
        id: 11,
        title: "Tempero Alagoas",
        image: ["assets/images/tempero-capa.png"],
        audio: "audio/tempero.mp3",
        partitura: ["assets/images/tempero-partitura.png"],

        video: "assets/videos/farol copy 11.MP4",
    },
    {
        id: 12,
        title: "Sou Alagoano",
        image: ["assets/images/soualagoano-capa.png"],
        audio: "audio/soualagoano.mp3",
        partitura: ["assets/images/sou-partitura.png"],

        video: "assets/videos/farol copy 12.MP4",
    },
    {
        id: 13,
        title: "Veja o Dó",
        image: ["assets/images/vejaodo.png"],
        audio: "audio/vejaodo.wav",
        partitura: ["assets/images/vejaodo-partitura.png"],

        video: "assets/videos/farol copy 13.MP4",
    },
    {
        id: 14,
        title: "O Sol",
        image: ["assets/images/sol.png"],
        audio: "audio/sol.mp3",
        partitura: ["assets/images/sol-partitura.png"],

        video: "assets/videos/farol copy 14.MP4",
    },
    {
        id: 15,
        title: "Dois Passarinhos",
        image: ["assets/images/doispassarinhos.png"],
        audio: "audio/choquinhas.mp3",
        partitura: ["assets/images/doispassarinhos-partitura.png"],

        video: "assets/videos/farol copy 15.MP4",
    },
    {
        id: 16,
        title: "Céu e Mar",
        image: ["assets/images/ceuemar.png"],
        audio: "audio/ceuemar.mp3",
        partitura: ["assets/images/ceuemar-partitura.png"],

        video: "assets/videos/farol copy 16.MP4",
    },
    {
        id: 17,
        title: "Céu Terra e Mar",
        image: ["assets/images/ceuterraemar.png"],
        audio: "audio/ceuterraemar.mp3",
        partitura: ["assets/images/ceuterraemar-partitura.png"],

        video: "assets/videos/farol copy 17.MP4",
    },
    {
        id: 18,
        title: "Três Lagoas",
        image: ["assets/images/treslagoas.png"],
        audio: "audio/treslagoas.mp3",
        partitura: ["assets/images/treslagoas-partitura.png"],

        video: "assets/videos/farol copy 18.MP4",
    },
    {
        id: 19,
        title: "Amigos da Natureza",
        image: ["assets/images/amigosdanatureza.png"],
        audio: "audio/amigosdanatureza.mp3",
        partitura: ["assets/images/amigosdanatureza-partitura.png"],

        video: "assets/videos/farol copy 19.MP4",
    },
    {
        id: 20,
        title: "Sururu",
        image: ["assets/images/sururu.png"],
        audio: "audio/sururu.mp3",
        partitura: ["assets/images/sururu-partitura.png"],

        video: "assets/videos/farol copy 20.MP4",
    },
    {
        id: 21,
        title: "O Cantor da Lagoa",
        image: ["assets/images/ocantordalagoas.png"],
        audio: "audio/cantordalagoa.mp3",
        partitura: ["assets/images/cantordalagoa-partitura.png"],

        video: "assets/videos/farol copy 21.MP4",
    },
    {
        id: 22,
        title: "A Arte e O Artista",
        image: ["assets/images/arteeoartista.png"],
        audio: "audio/arteartista.mp3",
        partitura: [
            "assets/images/arteeoartista-partitura-0.png",
            "assets/images/arteeoartista-partitura-1.png",
        ],

        video: "assets/videos/farol copy 22.MP4",
    },
    {
        id: 23,
        title: "Passáro Sete Cores",
        image: ["assets/images/passarosetecores.png"],
        audio: "audio/passarosetecores.mp3",
        partitura: ["assets/images/passarosetecores-partitura.png"],

        video: "assets/videos/farol copy 23.MP4",
    },
    {
        id: 24,
        title: "Coco Geladinho",
        image: ["assets/images/cocogeladinho.png"],
        audio: "audio/cocogeladinho.mp3",
        partitura: ["assets/images/cocogeladinho-partitura.png"],

        video: "assets/videos/farol copy 24.MP4",
    },
    {
        id: 25,
        title: "O Pinto da Madrugada",
        image: ["assets/images/pintodamadrugada.png"],
        audio: "audio/pintodamadrugada.mp3",
        partitura: ["assets/images/pintodamadrugada-partitura.png"],

        video: "assets/videos/farol copy 25.MP4",
    },
    {
        id: 26,
        title: "Maragogi",
        image: ["assets/images/maragogi.png"],
        audio: "audio/maragogi.mp3",
        partitura: ["assets/images/maragogi-partitura.png"],

        video: "assets/videos/farol copy 26.MP4",
    },
    {
        id: 27,
        title: "Água",
        image: ["assets/images/agua.png"],
        audio: "audio/agua.mp3",
        partitura: ["assets/images/agua-partitura.png"],

        video: "assets/videos/farol copy 27.MP4",
    },
    {
        id: 28,
        title: "Trio Nordestino",
        image: ["assets/images/trionordestino.png"],
        audio: "audio/trionordestino.mp3",
        partitura: ["assets/images/trionordestino-partitura.png"],

        video: "assets/videos/farol copy 8.MP4",
    },
    {
        id: 29,
        title: "Os Repentistas",
        image: ["assets/images/osrepentistas.png"],
        audio: "audio/osrepentistas.mp3",
        partitura: ["assets/images/osrepentistas-partitura.png"],

        video: "assets/videos/farol copy 29.MP4",
    },
    {
        id: 30,
        title: "Passeio na Orla",
        image: ["assets/images/passeio-capa.png"],
        audio: "audio/30passeionaorla.mp3",
        partitura: ["assets/images/passeio-partitura.png"],

        video: "assets/videos/farol copy 30.MP4",
    },
    {
        id: 31,
        title: "Praia do Francês",
        image: ["assets/images/frances-capa.png"],
        audio: "audio/31praia.mp3",
        partitura: ["assets/images/frances-partitura.png"],

        video: "assets/videos/farol copy 31.MP4",
    },
    {
        id: 32,
        title: "Verão",
        image: ["assets/images/verao-capa.png"],
        audio: "audio/32verao.mp3",
        partitura: [
            "assets/images/verao-partitura-0.png",
            "assets/images/verao-partitura-1.png",
        ],

        video: "assets/videos/farol copy 32.MP4",
    },
    {
        id: 33,
        title: "O Pintor de Alagoas",
        image: ["assets/images/pintor-capa.png"],
        audio: "audio/33opintor.mp3",
        partitura: [
            "assets/images/pintor-partitura-0.png",
            "assets/images/pintor-partitura-1.png",
        ],

        video: "assets/videos/farol copy 33.MP4",
    },
    {
        id: 34,
        title: "Renovando e Relembrando",
        image: ["assets/images/relembrando-capa.png"],
        audio: "audio/34renovalembra.mp3",
        partitura: ["assets/images/relembrando-partitura.png"],

        video: "assets/videos/farol copy 34.MP4",
    },
    {
        id: 35,
        title: "Maré",
        image: ["assets/images/mare-capa.png"],
        audio: "audio/35mare.mp3",
        partitura: ["assets/images/mare-partitura.png"],

        video: "assets/videos/farol copy 35.MP4",
    },
    {
        id: 36,
        title: "Peixe Boi",
        image: ["assets/images/peixe-boi-capa.png"],
        audio: "audio/36peixeboi.mp3",
        partitura: ["assets/images/peixe-boi-partitura.png"],

        video: "assets/videos/farol copy 36.MP4",
    },
    {
        id: 37,
        title: "Peixinho",
        image: ["assets/images/peixinho-capa.png"],
        audio: "audio/37peixinho.mp3",
        partitura: ["assets/images/peixinho-partitura.png"],

        video: "assets/videos/farol copy 37.MP4",
    },
    {
        id: 38,
        title: "Minha Jangada",
        image: ["assets/images/jangada-capa.png"],
        audio: "audio/38minhajangada.mp3",
        partitura: ["assets/images/jangada-partitura.png"],

        video: "assets/videos/farol copy 38.MP4",
    },
    {
        id: 39,
        title: "Caranguejo",
        image: ["assets/images/caranguejo-capa.png"],
        audio: "audio/39caranguejo.mp3",
        partitura: ["assets/images/caranguejo-partitura.png"],

        video: "assets/videos/farol copy 39.MP4",
    },
    {
        id: 40,
        title: "Penedo",
        image: ["assets/images/penedo-capa.png"],
        audio: "audio/40penedo.mp3",
        partitura: [
            "assets/images/penedo-partitura-0.png",
            "assets/images/penedo-partitura-1.png",
        ],

        video: "assets/videos/farol copy 40.MP4",
    },
    {
        id: 41,
        title: "A Valsa Do Palácio",
        image: ["assets/images/valsa-capa.png"],
        audio: "audio/valsa.mp3",
        partitura: ["assets/images/valsa-partitura.png"],

        video: "assets/videos/farol copy 41.MP4",
    },
    {
        id: 42,
        title: "A Catedral",
        image: ["assets/images/catedral-capa.png"],
        audio: "audio/catedral.mp3",
        partitura: ["assets/images/catedral-partitura.png"],

        video: "assets/videos/farol copy 42.MP4",
    },
    {
        id: 43,
        title: "Rota dos Milagres",
        image: ["assets/images/milagres-capa.png"],
        audio: "audio/milagres.mp3",
        partitura: ["assets/images/milagres-partitura.png"],

        video: "assets/videos/farol copy 43.MP4",
    },
    {
        id: 44,
        title: "Sete Coqueiros",
        image: ["assets/images/setecoqueiros-capa.png"],
        audio: "audio/44setecoqueiros.mp3",
        partitura: ["assets/images/setecoqueiros-partitura.png"],

        video: "assets/videos/farol copy 44.MP4",
    },
    {
        id: 45,
        title: "Graciliano Ramos",
        image: ["assets/images/graciliano-capa.png"],
        audio: "audio/45gracilianoramos.mp3",
        partitura: ["assets/images/graciliano-partitura.png"],

        video: "assets/videos/farol copy 45.MP4",
    },
    {
        id: 46,
        title: "Guerreiros",
        image: ["assets/images/guerreiros-capa.png"],
        audio: "audio/46guerreiros.mp3",
        partitura: ["assets/images/guerreiros-partitura.png"],

        video: "assets/videos/farol copy 46.MP4",
    },
    {
        id: 47,
        title: "Remando",
        image: ["assets/images/remando-capa.png"],
        audio: "audio/47remando.mp3",
        partitura: ["assets/images/remando-partitura.png"],

        video: "assets/videos/farol copy 47.MP4",
    },
    {
        id: 48,
        title: "Pescador",
        image: ["assets/images/pescador-capa.png"],
        audio: "audio/48pescador.mp3",
        partitura: ["assets/images/pescador-partitura.png"],

        video: "assets/videos/farol copy 48.MP4",
    },
    {
        id: 49,
        title: "Bumba Meu Boi",
        image: ["assets/images/bumba-capa.png"],
        audio: "audio/49bumba.mp3",
        partitura: [
            "assets/images/bumba-partitura-0.png",
            "assets/images/bumba-partitura-1.png",
        ],

        video: "assets/videos/farol copy 49.MP4",
    },
    {
        id: 50,
        title: "Viva o Teatro Deodoro",
        image: ["assets/images/teatro-capa.png"],
        audio: "audio/teatro.mpeg",
        partitura: [
            "assets/images/teatro-partitura-0.png",
            "assets/images/teatro-partitura-1.png",
        ],

        video: "assets/videos/farol copy 50.MP4",
    },
    {
        id: 51,
        title: "Gogó da Ema",
        image: ["assets/images/gogo-capa.png"],
        audio: "audio/50gogo.mp3",
        partitura: [
            "assets/images/gogo-partitura-1.png",
            "assets/images/gogo-partitura-0.png",
        ],

        video: "assets/videos/farol copy 51.MP4",
    },
    {
        id: 52,
        title: "Meu Paraíso",
        image: ["assets/images/paraiso-capa.png"],
        audio: "audio/52meuparaiso.m4a",
        partitura: [
            "assets/images/paraiso-partitura-0.png",
            "assets/images/paraiso-partitura-1.png",
        ],

        video: "assets/videos/farol copy 52.MP4",
    },
];

export default myIndice;
