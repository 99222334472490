import {
    View,
    Image,
    Text,
    ImageBackground,
    TouchableOpacity,
} from "react-native";

import { Video, Audio } from "expo-av";
import React, { useContext, useEffect, useRef, useState } from "react";
import Slider from "@react-native-community/slider";
import { useNavigation } from "@react-navigation/native";
import MusicContext from "../../../../contexts/aulaContext";
import { isLoading } from "expo-font";

interface Params {
    activeAudio: number;
}
export default function playAudio({ route }: any) {
    const { activeAudio }: Params = route.params;
    const [sound, setSound] = useState<Audio.Sound>();
    const [isPlaying, setIsPlaying] = useState<boolean>();
    const [range, setRange] = useState<number>(1);
    const navigation = useNavigation();
    const [activeChannel] = useContext(MusicContext);
    const [positionImage, setPositionImage] = useState(0);

    async function playSound() {
        const { sound } = await Audio.Sound.createAsync(
            require(`../../../../../assets/${activeChannel[activeAudio].audio}`),
            {
                progressUpdateIntervalMillis: 500,
                // positionMillis: milisPosition,
                shouldPlay: false,
                volume: 1.0,
                isMuted: false,
                isLooping: false,
            }
        );
        sound?.playAsync();
        setIsPlaying(true);
        setSound(sound);
    }

    async function PlayPause() {
        sound?.setPositionAsync(0);
    }
    async function stopSound() {
        setIsPlaying(false);

        sound?.stopAsync();
    }

    useEffect(() => {
        if (sound != null) {
            // alert("show");\

            // sound.setPositionAsync(milisPosition);
            Audio.setAudioModeAsync({ staysActiveInBackground: false });
            sound.setRateAsync(range, false);
        }
    }, [range, sound, positionImage]);

    return (
        <View
            style={{
                width: "100%",
                height: "100%",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: "#ffffff",
                justifyContent: "center",
                flex: 1,
            }}
        >
            <ImageBackground
                source={require("../../../../../assets/images/background.png")}
                resizeMode="stretch"
                style={{
                    width: "100%",
                    height: "100%",
                    flex: 1,
                }}
            >
                <TouchableOpacity
                    style={{
                        alignSelf: "flex-end",
                        width: "100%",
                        maxWidth: 100,
                        height: "100%",
                        maxHeight: 100,
                        position: "absolute",
                        zIndex: 99,
                    }}
                    onPress={() => {
                        sound?.stopAsync();
                        navigation.goBack();
                    }}
                >
                    <Image
                        style={{
                            width: "100%",
                            height: "100%",
                        }}
                        resizeMode={"contain"}
                        source={require("../../../../../assets/images/close.png")}
                    />
                </TouchableOpacity>

                <View
                    style={{
                        width: "100%",
                        height: "100%",
                        alignItems: "center",
                        justifyContent: "center",
                        zIndex: 1,
                    }}
                >
                    <View
                        style={{
                            width: "100%",
                            height: "100%",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <ImageBackground
                            resizeMode="contain"
                            source={require(`../../../../../${activeChannel[activeAudio].partitura[positionImage]}`)}
                            // source={require(`../../../../../assets/images/agua-partitura.png`)}
                            style={{
                                height: "100%",
                                width: "100%",
                                // minHeight: 400,
                                top: 0,
                                flex: 1,
                                alignItems: "center",
                                justifyContent: "flex-end",
                            }}
                        >
                            <View
                                style={{
                                    width: "100%",
                                    // height: "100%",
                                    marginLeft: 0,
                                    marginRight: 0,
                                    marginBottom: 0,
                                    // marginTop: 48,
                                    justifyContent: "center",
                                    alignItems: "center",
                                    flexDirection: "row",
                                }}
                            >
                                {positionImage > 0 && (
                                    <TouchableOpacity
                                        style={{
                                            width: "100%",
                                            maxWidth: 100,
                                            height: "100%",
                                            maxHeight: 100,
                                        }}
                                        onPress={() => {
                                            setPositionImage(positionImage - 1);
                                        }}
                                    >
                                        <Image
                                            style={{
                                                width: "100%",
                                                height: "100%",
                                            }}
                                            resizeMode={"contain"}
                                            source={require("../../../../../assets/images/back-arrow.png")}
                                        />
                                    </TouchableOpacity>
                                )}
                                <TouchableOpacity
                                    onPress={isPlaying ? PlayPause : playSound}
                                    style={{
                                        alignSelf: "center",
                                    }}
                                >
                                    <Image
                                        resizeMode="contain"
                                        style={{
                                            width: 100,
                                            height: 100,
                                        }}
                                        source={require("../../../../../assets/images/play-audio.png")}
                                    />
                                </TouchableOpacity>

                                <TouchableOpacity
                                    onPress={() => {
                                        setIsPlaying(false);
                                        sound?.unloadAsync();
                                        navigation.navigate("playVideo", {
                                            activeVideo: activeAudio,
                                        });
                                    }}
                                >
                                    <Image
                                        resizeMode="cover"
                                        style={{
                                            width: 100,
                                            height: 100,
                                        }}
                                        source={require("../../../../../assets/images/play-video.png")}
                                    />
                                </TouchableOpacity>
                                <TouchableOpacity onPress={stopSound}>
                                    <Image
                                        resizeMode="cover"
                                        style={{
                                            width: 100,
                                            height: 100,
                                        }}
                                        source={require("../../../../../assets/images/stop.png")}
                                    />
                                </TouchableOpacity>
                                <ImageBackground
                                    resizeMode="contain"
                                    source={require("../../../../../assets/images/andamento.png")}
                                    style={{
                                        width: "100%",
                                        height: "100%",
                                        padding: 16,
                                        maxWidth: 270,
                                        maxHeight: 100,
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}
                                >
                                    <Slider
                                        style={{
                                            height: "100%",
                                            width: "100%",
                                            padding: 32,
                                            // maxHeight: 5,
                                        }}
                                        step={0.25}
                                        minimumValue={0.5}
                                        maximumValue={1.5}
                                        onValueChange={(value) =>
                                            setRange(value)
                                        }
                                        value={range}
                                        // minimumTrackImage={require("../../../../../assets/images/icon-andamento.png")}
                                        thumbTintColor={"#964b00"}
                                        trackImage={require("../../../../../assets/images/icon-andamento.png")}
                                        minimumTrackTintColor="none"
                                        maximumTrackTintColor="none"
                                    />
                                    {/* <Text>{parseFloat(String(range)) + "x"}</Text> */}
                                </ImageBackground>
                                {positionImage !=
                                    activeChannel[activeAudio].partitura
                                        .length -
                                        1 && (
                                    <TouchableOpacity
                                        style={{
                                            width: "100%",
                                            maxWidth: 100,
                                            height: "100%",
                                            maxHeight: 100,
                                        }}
                                        onPress={() => {
                                            setPositionImage(positionImage + 1);
                                        }}
                                    >
                                        <Image
                                            style={{
                                                width: "100%",
                                                height: "100%",
                                            }}
                                            resizeMode={"contain"}
                                            source={require("../../../../../assets/images/righ-arrow.png")}
                                        />
                                    </TouchableOpacity>
                                )}
                            </View>
                        </ImageBackground>
                    </View>
                </View>
            </ImageBackground>
        </View>
    );
}
